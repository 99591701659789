.grade-log-subTable-container > .ant-table-cell {
  padding: 0;
}

.grade-log-subTable-container .ant-table {
  margin-left: 0;
}

.grade-log-subTable-container .subgrades-table-row td {
  border-bottom: 0;
}
