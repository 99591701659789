.subgrades-container > .ant-table-cell {
  padding: 0;
}

.subgrades-container thead {
  display: none;
}

.subgrades-container .ant-table {
  margin-left: 0;
}

.subgrades-container .subgrades-table-row td {
  border-bottom: 0;
}
